import React from 'react'
import styled from 'styled-components'
import theme from 'stories/utils/theme'
import { useTranslation } from 'next-i18next'
import { Headline2 } from 'stories/elements/Typography/Text'
import CodeFrameUSWebp from 'assets/homepage/us_code_frame.webp'
import CodeFrameWebp from 'assets/homepage/code_frame.webp'
import SmallCodeFrameWebp from 'assets/homepage/small_code_frame.webp'
import DownloadButton from 'components/modules/SEO/DownloadBanner'
import NextImage from 'components/elements/NextImage'
import { useRouter } from 'next/router'
import { getCountryLanguageConfig } from 'lib/constants/countryLanguageConfig'

const Container = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 1000px;
    background-color: ${theme.colors.snowWhite};
    overflow: hidden;
    display: grid;
    place-content: center;
    align-items: center;
    position: relative;
    grid-template-columns: 1fr 450px;
    padding: 0 15%;
    @media (max-width: 1440px) {
        padding: 0 10%;
    }
    @media (max-width: 1250px) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 0 15%;
        h2 {
            width: 100vw;
            padding: 0 10%;
            text-align: center;
        }
    }

    @media (max-width: 750px) {
        min-height: auto;
        height: 700px;
        padding-left: 50px;
        padding-top: -50px;

        h2 {
            width: 100%;
            text-align: left;
            position: absolute;
            top: 15px;
            left: 15px;
        }
    }
`

const PhoneWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    position: relative;
    .element {
        position: absolute;
        z-index: 3;
        top: 125px; //half of the phone height
        left: 35px;
    }
    .phone {
        z-index: 2;
    }

    @media (max-width: 1250px) {
        display: flex;
        justify-content: center;
        left: 15%;
    }
    .mobile-buttons {
        display: none;
    }
    @media (max-width: 750px) {
        display: grid;
        .element {
            top: 100px; //half of the phone height
            left: 66px;
        }
        .phone {
            position: relative;
            top: 75px;
            left: 65px;
            width: 466px;
            height: 538px;
        }
        .mobile-buttons {
            display: grid;
            place-items: center;
            position: absolute;
            top: 275px;
            width: 100%;
            left: -40px;
            margin: 0 auto;
            gap: 20px;
            padding-left: 0;
            z-index: 3;
        }
    }
`

const TextWrapper = styled.div`
    .buttons {
        display: grid;
        gap: 10px;
    }
    .button {
        width: 225px;
    }
    @media (max-width: 1250px) {
        h2 {
            margin-bottom: 10px;
        }
        .buttons {
            display: flex;
            justify-content: center;
            padding: 20px;
        }
    }
    @media (max-width: 750px) {
        .buttons {
            display: none;
        }
    }
`

interface Props {
    isMobile?: boolean
}

const CodeComponent = ({ isMobile }: Props) => {
    const { t } = useTranslation('homepage')
    const router = useRouter()
    const { locale } = router
    const countryLanguageConfig = getCountryLanguageConfig(locale)
    const isLaunched = countryLanguageConfig?.isLaunched

    const DesktopFrame = isLaunched ? CodeFrameWebp : CodeFrameUSWebp

    return (
        <Container>
            <PhoneWrapper>
                {isMobile ? (
                    <>
                        <div className="phone">
                            <NextImage
                                src={SmallCodeFrameWebp.src}
                                height={SmallCodeFrameWebp.height}
                                width={SmallCodeFrameWebp.width}
                                alt="Mobile frame background"
                                lazyLoading
                            />
                        </div>
                        <div className="mobile-buttons">
                            <DownloadButton type="ios" referrerScreen="homepage" isNotLaunched={!isLaunched} />
                            <DownloadButton type="android" referrerScreen="homepage" isNotLaunched={!isLaunched} />
                        </div>
                    </>
                ) : (
                    <div className="phone">
                        <NextImage
                            src={DesktopFrame.src}
                            height={DesktopFrame.height}
                            width={DesktopFrame.width}
                            alt="Frame background"
                            lazyLoading
                        />
                    </div>
                )}
            </PhoneWrapper>
            <TextWrapper>
                <Headline2 color={theme.colors.headlineDark} marginBottom={50}>
                    {t('homepage/codeCTAText')}
                </Headline2>
                <div className="buttons">
                    <DownloadButton type="ios" referrerScreen="homepage" isNotLaunched={!isLaunched} />
                    <DownloadButton type="android" referrerScreen="homepage" isNotLaunched={!isLaunched} />
                </div>
            </TextWrapper>
        </Container>
    )
}

export default CodeComponent
